<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">Войти или создать профиль</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="System" name="close-line" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs" v-if="step === 0">
        <span class="login-modal__inputs-title">Контактный телефон</span>
        <div class="login-modal__inputs-list">
          <InputComponent v-model="numberCode" placeholder="+7" mask="+###" />
          <InputComponent
            v-model="number"
            :error="error"
            placeholder="(___) ___ - __ - __"
            mask="(###) ### - ## - ##"
            @keydown="userLogin"
          />
        </div>
      </div>
      <InputComponent
        v-if="step === 1"
        v-model="code"
        :error="error"
        :title="confirmTitle"
        mask="####"
        placeholder="Последние 4 цифры номера"
        autofocus
        @keydown="phoneConfirm"
      />
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--main" v-if="step === 0" @click="userLogin">
        <FileIconComponent v-if="loading" name="loading" />
        <template v-else>Получить код</template>
      </button>
      <button type="button" class="btn btn--md btn--main" v-if="step === 1" @click="phoneConfirm">
        <FileIconComponent v-if="loading" name="loading" />
        <template v-else>Подтвердить</template>
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import USER_LOGIN from "gql/auth/UserLogin.graphql";
import USER_PHONE_CONFIRM from "gql/auth/UserPhoneConfirm.graphql";
import FileIconComponent from "components/FileIconComponent.vue";
import axios from "axios";

// step 0 - Ввод номера телефона
// step 1 - Ввод кода из смс
export default {
  name: "LoginModal",
  props: {
    routeAfterLogin: Object,
  },
  data() {
    return {
      step: 0,
      numberCode: "+7",
      number: null,
      code: null,
      loading: false,
      error: null,
      confirmTitle: "Введите 4-значный код из SMS сообщения.",
    };
  },
  methods: {
    userLogin() {
      if (!this.loading) {
        if (this.number) {
          this.loading = true;
          this.error = null;
          this.$apollo.provider.defaultClient
            .mutate({
              mutation: USER_LOGIN,
              variables: {
                phone: this.numberCode + this.number.replace(/\D+/g, ""),
              },
            })
            .then(() => {
              this.loading = false;
              this.step = 1;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.error = "Введите номер телефона";
        }
      }
    },
    phoneConfirm() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_CONFIRM,
            variables: {
              code: parseInt(this.code),
            },
          })
          .then(({ data }) => {
            if (data && data.UserPhoneConfirm) {
              this.$store.state.auth.user = data.UserPhoneConfirm.user;
              this.$store.state.apollo_token = data.UserPhoneConfirm.token;
              // Сохраняю токен в сессии
              axios({
                method: "post",
                url: "/token",
                data: {
                  token: data.UserPhoneConfirm.token,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              }).then(() => {
                // сохраняем товары в корзине из store, куда они добавлются из localStorage в App.vue
                this.$store
                  .dispatch("CART_ADD_ITEMS", {
                    apollo: this.$apollo,
                    variables: {
                      items: this.$store.state.auth.cart.map((c) => {
                        return {
                          id: c.item?.id,
                          count: c.count,
                        };
                      }),
                    },
                  })
                  .then(() => {
                    this.$store.dispatch("GET_CART_ITEMS", {
                      apollo: this.$apollo,
                    });
                  });
                this.$router
                  .push(this.routeAfterLogin || { name: "user" })
                  .then(() => {
                    this.loading = false;
                    location.reload();
                  })
                  .catch(() => {
                    this.loading = false;
                    this.$emit("close");
                  });
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.error = "Введен неправильный код подтверждения";
          });
      }
    },
  },
  components: { FileIconComponent, InputComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.login-modal {
  &__inputs {
    display grid
    grid-gap 4px
    width 100%
  }

  &__inputs-list {
    display flex
    align-items flex-start
    gap 8px
    width 100%

    .input:first-child {
      width 110px
    }

    .input:last-child {
      width 100%
    }
  }

  &__inputs-title {
    display flex
    align-items center
    gap 8px
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
